<template>
	
</template>
<script>
import { CONSTANT } from '../constant';
export default {
	created(){
		this.$store.commit('setPlantInqId', 0);
		this.$router.push({
			name: "PlantEdit",
			params: { mode: CONSTANT.MODE_ADD },
		});
	}
}
</script>